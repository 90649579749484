/*
|--------------------
|     PAGE HOME
|--------------------
*/
.home {
    #header {
        box-shadow: none;
        background-color: #2238b0;
        transition: all ease 0.3s;
        .item-link {
            color: white !important;
        }
        .btn {
            color: $white;
            border: 1px solid $white;
            background-color: transparent;
            &:hover {
                color: $blue;
                border: 1px solid $white;
                background-color: $white;
                transition: all ease 0.3s;
            }
        }
        &.top {
            background: transparent;
            transition: all ease 0.3s;
        }
        .item-menu li:hover:after,
        .item-menu li.current-menu-item:after {
            content: "";
            background-color: white;
        }
    }
    .item-burger {
        & > span {
            background-color: white !important;
            transition: all ease 0.8s !important;
        }
    }
    #block-banner-home {
        background-color: #f8f9ff;
        height: 100%;
        overflow: hidden;
        position: relative;
        aspect-ratio: 16/9;
        @include media-breakpoint-down(md) {
            min-height: initial;
        }
        &:after {
            content: "";
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            opacity: 0.5;
            position: absolute;
            background-color: black;
        }
        .title-h1 {
            display: flex;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            width: fit-content;
            position: absolute;
            .details {
                h1 {
                    font-family: "SuisseIntl-Bold";
                    font-size: 56px;
                    line-height: 63px;
                    text-align: center;
                    letter-spacing: -0.01em;
                    text-transform: uppercase;
                    color: white;
                    @include media-breakpoint-down(sm) {
                        font-size: 40px;
                        line-height: 44px;
                    }
                    @include media-breakpoint-down(xs) {
                        font-size: 26px;
                        line-height: 28px;
                    }
                }
            }
        }
        video {
            width: 100%;
            aspect-ratio: 16/9;
        }
    }
    #block-chiffres {
        margin-top: -90px;
        .container {
            z-index: 1;
            padding: 70px 15px;
            background: #ffffff;
            box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.03);
        }
    }
}

.vignette {
    position: fixed;
    height: 113px;
    width: 113px;
    z-index: 5;
    bottom: 140px;
    right: 40px;
    transition: all ease 0.4s;
    @include media-breakpoint-down(sm) {
        height: 80px;
        width: 80px;
        right: 10px;
        background-size: cover;
    }
    &.bottom {
        bottom: 40px;
        transition: all ease 0.4s;
    }
}
