/*
|--------------------
|       404
|--------------------
*/

#page-404 {
    /*
    |
    | Section contact
    |------------------
    */
    .section-content {
        font-family: "SuisseIntl-Black";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: $very-dark-grey;
        color: $white;
        text-align: center;
        background-position: center;
        background-size: cover;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            opacity: 0.3;
        }
        .container {
            z-index: 1;
            h1 {
                font-size: 5rem;
                font-weight: 300;
                line-height: 1;
                color: white;
            }
            .item-title {
                a {
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }
}
