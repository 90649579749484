/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Set paddings & margins sizes properties
|
|
*/
$xs:  5px;
$sm:  15px;
$md:  30px;
$lg:  50px;
$xl:  70px;
$xxl: 140px;