#block-services {
    color: white;
    min-height: 900px;
    position: relative;
    h2 {
        color: white;
        b,
        strong {
            color: white;
        }
    }
    .text {
        font-family: "SuisseIntl-Regular";
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
    }
    .elem-list {
        font-family: "SuisseIntl-Regular";
        font-size: 24px;
        line-height: 23px;
        color: #ffffff;
        .title {
            display: block;
            transition: all ease 0.4s;
        }
        a {
            font-family: "SuisseIntl-Regular";
            font-size: 24px;
            line-height: 26px;
            color: #ffffff;
            display: flex;
            vertical-align: middle;
            align-items: center;
            width: 100%;
            padding: 28px 36px 28px 0;
            @include media-breakpoint-down(lg) {
                padding: 28px 0;
                display: block;
            }
            &:before {
                content: "";
                height: 21px;
                width: 32px;
                display: block;
                right: 0;
                position: absolute;
                transition: all ease 0.4s;
                background-image: url("../img/icon/arr-w.svg");
            }
            &:after {
                content: "";
                height: 1px;
                width: 100%;
                opacity: 0.2;
                left: 0;
                bottom: 0;
                position: absolute;
                border: 1px solid #f2f4ff;
            }
            @include media-breakpoint-down(lg) {
                .bg {
                    height: 300px;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                }
            }
            @include media-breakpoint-down(lg) {
                .title {
                    margin-right: 27px;
                    hyphens: auto;
                }
            }
        }

        @include media-breakpoint-up(md) {
            &:hover {
                .title {
                    padding-left: 30px;
                    transition: all ease 0.4s;
                }
                a {
                    &:before {
                        content: "";
                        margin-right: 30px;
                        transition: all ease 0.4s;
                    }
                }
            }
        }
    }
    .block-list {
        display: flex !important;
        align-items: center;
        vertical-align: middle;
        @include media-breakpoint-down(lg) {
            display: none !important;
        }
        .block-img-list {
            width: 100%;
            .img-list {
                width: 100%;
                height: 583px;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

#block-question {
    background-color: $light-blue;
    h2 {
        font-family: "SuisseIntl-Black";
        font-style: normal;
        font-weight: 900;
        font-size: 44px;
        line-height: 56px;
        text-transform: uppercase;
        color: #182989;
    }
}

#block-chiffres {
    overflow: hidden;
    .item {
        margin: auto;
        display: grid;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        &:after {
            content: "";
            opacity: 0.30000001192092896;
            background: #fff;
            height: 100%;
            width: 1px;
            position: absolute;
            right: -76px;
            @include media-breakpoint-down(xl) {
                right: -14px;
            }
            @include media-breakpoint-down(lg) {
                content: none;
            }
        }
        &:last-child {
            &:after {
                content: none;
            }
        }
        h2 {
            font-family: "SuisseIntl-Black";
            font-style: normal;
            font-weight: 900;
            font-size: 44px;
            line-height: 56px;
            text-transform: uppercase;
            color: #182989;
            @include media-breakpoint-down(sm) {
                font-size: 80px;
                line-height: 80px;
            }
        }
        .value {
            margin: auto;
            display: grid;
            .chiffre {
                font-family: "Suisse Intl";
                font-style: normal;
                font-weight: 500;
                font-size: 70px;
                line-height: 90px;
                text-align: center;
                color: #182989;
                @include media-breakpoint-down(sm) {
                    font-size: 50px;
                    line-height: 50px;
                }
            }
            .unite {
                font-size: 40px;
                line-height: 50px;
                color: #182989;
            }
        }
        .description {
            font-family: "SuisseIntl-Regular";
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #182989;
            width: 300px;
            @include media-breakpoint-down(sm) {
                width: initial;
            }
        }
    }
}

#block-intro-home {
    .hblock {
        min-height: 750px;
        position: relative;
    }
    .text {
        font-family: "SuisseIntl-Regular";
        font-size: 24px;
        line-height: 34px;
        @include media-breakpoint-down(sm) {
            font-size: 20px;
            line-height: 28px;
        }
    }
    .image-1 {
        width: 608px;
        height: 803px;
        position: absolute;
        top: -50px;
        left: 0;
        z-index: 1;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(xl) {
            width: 488px;
            height: 623px;
        }
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 270px;
            top: 40px;
            position: relative;
            transform: inherit !important;
        }
    }
    .image-2 {
        width: 288px;
        height: 364px;
        position: absolute;
        right: 60px;
        bottom: 20px;
        z-index: 4;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 270px;
            position: relative;
            right: inherit;
            transform: inherit !important;
            margin: 40px 0;
        }
    }
}

#block-imagetext-home {
    .details {
        display: flex !important;
        align-items: center;
        vertical-align: middle;
        @include media-breakpoint-down(lg) {
            display: block !important;
            align-items: initial;
            vertical-align: initial;
            text-align: center;
        }
    }
    h2 {
        font-family: "SuisseIntl-Black";
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;
        text-transform: uppercase;
        color: $blue;
    }
    .rel {
        height: 600px;
    }
    .text {
        font-family: "SuisseIntl-Regular";
        font-size: 16px;
        line-height: 24px;
    }
    .image-0 {
        width: 451px;
        height: 564px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(xl) {
            width: 348px;
            height: 573px;
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .image-2 {
        width: 288px;
        height: 200px;
        position: absolute;
        right: 60px;
        bottom: 70px;
        z-index: 4;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .label {
        width: 209px;
        height: 196px;
        position: absolute;
        left: 60px;
        top: 100px;
        z-index: 4;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .img-label-mob {
        width: 209px;
        height: 196px;
        top: 50px;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        background-size: cover;
        background-position: center;
    }
    .img-mob {
        width: 100%;
        height: 300px;
        margin: 30px 0;
        background-size: cover;
        background-position: center;
    }
}

#block-partenaires {
    margin-top: -30px;
    .partenaires {
        z-index: 1;
        padding: 70px 15px;
        background: #ffffff;
        box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 3%);

        .item {
            margin: auto;
            display: grid;
            img {
                max-width: 90px;
                max-height: 90px;
            }
            .value {
                margin: auto;
                display: grid;
                .chiffre {
                    font-family: "Suisse Intl";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 70px;
                    line-height: 90px;
                    text-align: center;
                    color: #182989;
                }
                .unite {
                    font-size: 40px;
                    line-height: 50px;
                    color: #182989;
                }
            }
            .description {
                font-family: "SuisseIntl-Regular";
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #182989;
            }
        }
    }
}

#block-nous-rejoindre {
    color: white;
    overflow: hidden;
    .rejoindre {
        h2 {
            color: white;
            strong {
                color: white;
            }
        }
        .text {
            font-family: "SuisseIntl-Regular";
            font-size: 18px;
            line-height: 28px;
            color: #ffffff;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
        .btn {
            @include media-breakpoint-down(sm) {
                margin: auto;
                display: table;
            }
        }
    }
    .swiper-slide {
        .item {
            height: 539px;
            width: 100%;
            @include media-breakpoint-down(sm) {
                height: 340px;
            }
            div {
                width: 100%;
                .bg {
                    height: 539px;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    @include media-breakpoint-down(sm) {
                        height: 340px;
                    }
                }
            }
        }
        &.even {
            .item {
                .bg {
                    height: 364px;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    @include media-breakpoint-down(sm) {
                        height: 200px;
                    }
                }
            }
        }
    }
}

#block-slider-actus {
    color: white;
    overflow: hidden;
    .block-btn {
        position: absolute;
        right: 0;
        bottom: 20px;
        @include media-breakpoint-down(lg) {
            right: initial;
            position: relative;
        }
        @include media-breakpoint-down(sm) {
            margin: auto;
        }
    }
    .swiper-slide {
        .item {
            height: 539px;
            width: 100%;
            @include media-breakpoint-down(sm) {
                height: 340px;
            }
            div {
                width: 100%;
                .bg {
                    height: 539px;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    @include media-breakpoint-down(sm) {
                        height: 340px;
                    }
                }
            }
        }
        &.odd {
            .item {
                .bg {
                    height: 364px;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    @include media-breakpoint-down(sm) {
                        height: 200px;
                    }
                }
            }
        }
    }
}

#block-video {
    h2 {
        font-family: "SuisseIntl-Black";
        font-style: normal;
        font-weight: 900;
        font-size: 44px;
        line-height: 56px;
        text-transform: uppercase;
        color: #182989;
        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 30px;
        }
        @include media-breakpoint-down(xs) {
            font-size: 24px;
            line-height: 28px;
        }
    }
}

.legend {
    font-family: "SuisseIntl-Regular";
    font-size: 14px;
    line-height: 22px;
    color: #37425e;
}

#block-rs {
    border-top: 1px solid #eaecf0;
    .rs {
        margin: auto;
        display: table;
        li {
            list-style: none;
            float: left;
            margin: 10px;
            a {
                height: 27px;
                width: 27px;
                transition: all ease 0.4s;
                &:hover {
                    transition: all ease 0.4s;
                    opacity: 0.6;
                }
                img {
                    min-height: 20px;
                }
            }
        }
    }
}

#block_quote {
    position: relative;
    &::before {
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        background: #cf3120;
    }
    .content {
        font-family: "Suisse Intl";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.03em;
        color: #001c60;
    }
    .author {
        font-family: "SuisseIntl-Regular";
        font-size: 16px;
        line-height: 24px;
        color: #37425e;
    }
    &.no-author {
        .cms {
            padding-left: 0 !important;
        }
        &::before {
            content: none;
        }
    }
}

#block_file {
    width: 100%;
    h2 {
        font-family: "SuisseIntl-Black";
        font-size: 44px;
        line-height: 56px;
        text-align: center;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        span {
            display: block;
            font-family: "SuisseIntl-Regular";
        }
    }
    .file-text {
        font-size: 16px;
        line-height: 24px;
        font-family: "SuisseIntl-Regular";
    }
    .file {
        display: block;
        font-family: "SuisseIntl-Regular";
        font-size: 16px;
        line-height: 24px;
        background: #ffffff;
        width: 580px;
        height: 70px;
        position: relative;
        @include media-breakpoint-down(lg) {
            width: 500px;
        }
        @include media-breakpoint-down(md) {
            width: 90%;
        }
        .block-file {
            font-family: "SuisseIntl-Regular";
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #37425e;
            text-decoration: none;
            .img {
                height: 42px;
                width: 42px;
                border-radius: 50%;
                display: inline-block;
                background-color: $blue;
                margin-left: 12px;
                img {
                    margin-top: 9px;
                    max-height: 20px;
                }
            }
            .detail {
                margin-left: 15px;
                font-family: "SuisseIntl-Regular";
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #37425e;
                .other {
                    font-family: "SuisseIntl-Regular";
                    font-size: 14px;
                    line-height: 22px;
                    color: #667291;
                    margin: 0 10px;
                }
            }
            .download {
                margin-right: 15px;
                position: absolute;
                right: 0;
                img {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}

#block-temoignages {
    overflow: hidden;
    background: #f2f4ff;
    h2 {
        color: #182989;
        font-size: 44px;
        line-height: 56px;
        @include media-breakpoint-down(md) {
            display: block;
        }
        b,
        strong {
            color: #182989;
            font-size: 44px;
            line-height: 56px;
            margin-top: 0;
            margin: 0 10px;
            display: contents;
        }
    }
    @include media-breakpoint-down(md) {
        .block-swiper-buttons {
            top: 40px;
        }
    }
    .custom-card-temoignage {
        .text {
            font-family: "SuisseIntl-Regular";
            font-size: 20px;
            line-height: 30px;
            letter-spacing: -0.01em;
            color: #001c60;
        }
        .name {
            font-family: "SuisseIntl-Regular";
            font-size: 20px;
            line-height: 30px;
            letter-spacing: -0.01em;
            color: #001c60;
        }
        .role {
            font-family: "SuisseIntl-Regular";
            font-size: 16px;
            line-height: 24px;
            color: #37425e;
        }
    }
}

#block-engagements {
    overflow: hidden;
    h2 {
        color: #182989;
        font-size: 44px;
        line-height: 56px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        @include media-breakpoint-down(md) {
            display: block;
        }
        b,
        strong {
            color: #182989;
            font-size: 44px;
            line-height: 56px;
            margin-top: 0;
            margin: 0 10px;
        }
    }
    @include media-breakpoint-down(md) {
        .block-swiper-buttons {
            top: 40px;
        }
    }
    .custom-card-engagements {
        height: 560px;
        @include media-breakpoint-down(lg) {
            height: initial;
        }
        .title {
            font-family: "SuisseIntl-Black";
            font-style: normal;
            font-weight: 900;
            font-size: 32px;
            line-height: 38px;
            text-transform: uppercase;
            color: #001c60;
        }
        .text {
            font-family: "SuisseIntl-Regular";
            font-size: 16px;
            line-height: 24px;
            color: #37425e;
        }
    }
    .swiper-engagements2 {
        width: 100%;
        overflow: hidden;
        .bg {
            height: 560px;
            width: 100%;
            background-size: cover;
            background-position: center;
            @include media-breakpoint-down(lg) {
                height: 260px;
            }
        }
    }
}

#block-emplois {
    overflow: hidden;
    h2 {
        color: #182989;
        font-size: 44px;
        line-height: 56px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        @include media-breakpoint-down(md) {
            display: block;
        }
        b,
        strong {
            color: #182989;
            font-size: 44px;
            line-height: 56px;
            margin: 0 10px;
        }
    }

    @include media-breakpoint-down(md) {
        .block-swiper-buttons {
            top: 40px;
        }
    }
    .custom-card-emploi {
        min-height: 350px;
        background: #ffffff;
        box-shadow: 0px 20px 25px -5px rgba(93, 96, 117, 0.1);
        border-radius: 8px;
        .head-card {
            .types {
                .item {
                    font-family: "SuisseIntl-Regular";
                    font-size: 14px;
                    line-height: 31px;
                    color: #182989;
                    display: inline-block;
                    background: #f2f4ff;
                    border-radius: 16px;
                    padding: 4px 12px;
                }
            }
            .date {
                font-family: "SuisseIntl-Regular";
                font-size: 14px;
                line-height: 22px;
                color: #37425e;
                margin-bottom: 4px;
            }
        }
        .details-card {
            .title {
                font-family: "Suisse Intl";
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -0.03em;
                color: #182989;
                display: block;
            }
            .text {
                font-family: "SuisseIntl-Regular";
                font-size: 16px;
                line-height: 24px;
                color: #37425e;
                display: block;
            }
            .link {
                font-family: "SuisseIntl-Bold";
                font-size: 13px;
                line-height: 16px;
                text-transform: uppercase;
                color: #182989;
                display: block;
                position: absolute;
                left: 30px;
                bottom: 30px;
                &:before {
                    content: "";
                    width: 16px;
                    height: 15px;
                    display: block;
                    float: right;
                    padding: 0 2px 0 0;
                    transition: all ease 0.3s;
                    transform: rotate(180deg);
                    transition: all ease 0.3s;
                    background-repeat: no-repeat;
                    background-image: url(../img/icon/arrow-b.svg);
                }
            }
        }
        &:hover {
            .link {
                &:before {
                    padding: 0 6px 0 0;
                    transition: all ease 0.3s;
                }
            }
        }
    }
}

#block-image-text-blue {
    color: white;

    .bg {
        width: 100%;
        height: 800px;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(lg) {
            height: 300px;
        }
    }
    .block-text {
        right: 0;
        position: absolute;
        @include media-breakpoint-down(lg) {
            right: initial;
            position: relative;
        }
        h2 {
            color: white;
            position: relative;
            margin-left: 25px;
            strong {
                color: white;
            }
            &:before {
                content: "";
                width: 3px;
                height: 100%;
                background-color: white;
                position: absolute;
                display: block;
                left: -20px;
            }
        }
        .text,
        p {
            font-family: "SuisseIntl-Regular";
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
        }
    }
    .invers {
        .block-text {
            left: 0;
            right: initial;
        }
    }
}

#block-text-image {
    color: #fff;
    overflow: hidden;
    .bg {
        width: 100%;
        height: 500px;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(lg) {
            height: 300px;
        }
    }
    h2 {
        font-family: "Suisse Intl";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.03em;
        color: #041b9f;
        position: relative;
        margin-left: 25px;
        strong {
            color: white;
        }
        &:before {
            content: "";
            width: 3px;
            height: 100%;
            background-color: #cf3120;
            position: absolute;
            display: block;
            left: -20px;
        }
    }
    .text {
        font-family: "SuisseIntl-Regular";
        font-size: 16px;
        line-height: 24px;
        color: #37425e;
    }
}
#block-history {
    overflow: hidden;
    .block-history-items {
        width: 100%;
        overflow: hidden;
        .elem {
            position: relative;
            height: 30px;
            width: calc(100% / 3);
            display: flex;
            vertical-align: middle;
            align-items: center;
            float: left;
            &:first-child {
                margin-left: 0;
            }
            .dote {
                content: "";
                height: 22px;
                width: 22px;
                display: block;
                top: 4px;
                position: absolute;
                z-index: 2;
                background-size: cover;
                background-image: url("../img/icon/slide-point.png");
            }
            .progressbar-history {
                background-color: rgb(229, 233, 235);
                height: 2px;
                position: relative;
                width: 100%;
                overflow: hidden;
                margin-left: 15px;
                .progressbar-history-item {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: -100%;
                    top: 0;
                    bottom: 0;
                    display: block;
                    background-color: #e04542;
                    z-index: 1;
                }
            }
        }
        .item {
            @include media-breakpoint-down(sm) {
                padding: 0 15px;
                text-align: center;
            }
            .year {
                font-family: "SuisseIntl-Regular";
                font-size: 56px;
                line-height: 56px;
                letter-spacing: -0.03em;
                text-transform: uppercase;
                color: #182989;
            }
            .bg {
                width: 100%;
                max-width: 300px;
                height: 140px;
                background-position: center;
                background-size: cover;
                @include media-breakpoint-down(sm) {
                    margin: 20px auto;
                }
            }
            .desc {
                font-family: "SuisseIntl-Regular";
                font-size: 16px;
                line-height: 24px;
                color: #37425e;
            }
        }
    }
}

#block-diaporama {
    position: relative;
    overflow: hidden;
    h2 {
        font-family: "SuisseIntl-Black";
        font-style: normal;
        font-weight: 900;
        font-size: 44px;
        line-height: 56px;
        text-transform: uppercase;
        color: #182989;
    }
    .bg {
        width: 100%;
        height: 570px;
        margin: 0 auto;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(lg) {
            height: 340px;
        }
    }
    .desc {
        font-family: "SuisseIntl-Regular";
        font-size: 16px;
        line-height: 24px;
        color: #37425e;
        width: 600px;
        margin: auto;
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
    .block-swiper-buttons {
        height: 40px;
        width: 100%;
        position: absolute;
        right: 0;
        left: 0;
        top: 265px;
        z-index: 1;
        @include media-breakpoint-down(lg) {
            width: 200px;
            margin: auto;
            left: inherit;
            right: inherit;
            position: relative;
            top: 0;
        }
    }
    .block-btn {
        .swiper-buttons {
            top: initial;
            left: 0;
            position: absolute;
            bottom: 0px;
            z-index: 6;
            height: 40px;
            width: 100%;
            display: block;
            .button-next,
            .button-prev {
                height: 84px;
                width: 84px;
                float: left;
                position: relative;
                cursor: pointer;
                box-sizing: border-box;
                border-radius: 50%;
                transition: all ease 0.3s;
                z-index: 1;
                margin: 0px;
                border: 2px solid #182989;
                display: flex;
                vertical-align: middle;
                align-items: center;
                @include media-breakpoint-down(xl) {
                    height: 74px;
                    width: 74px;
                }
                &:before {
                    content: "";
                    background-size: cover;
                    position: relative;
                    height: 22px;
                    width: 22px;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    display: block;
                }
            }
            .button-next {
                float: right;
            }
            .swiper-button-disabled {
                opacity: 0.4;
                &:before {
                    content: "";
                    opacity: 1;
                }
            }
        }
    }
}

#block-expertises {
    h2 {
        color: #182989;
        font-size: 44px;
        line-height: 56px;
        b,
        strong {
            color: #182989;
            font-size: 44px;
            line-height: 56px;
            margin: 0 10px;
            display: contents;
        }
    }
    .bg {
        width: 100%;
        height: 570px;
        padding: 15px;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(lg) {
            height: 240px;
        }
    }
    .title {
        font-family: "SuisseIntl-Regular";
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        text-transform: uppercase;
        color: #ffffff;
        width: 50%;
        display: block;
        position: absolute;
        bottom: 10px;
    }
}

#block-list-questions {
    background: #f2f4ff;
    h2 {
        color: #182989;
        font-size: 44px;
        line-height: 56px;
        b,
        strong {
            color: #182989;
            font-size: 44px;
            line-height: 56px;
            margin: 0 10px;
            display: contents;
        }
    }
    ul {
        border: 1px solid #f2f4ff;
        box-shadow: 10px 20px 25px -5px rgba(93, 96, 117, 0.1);
        border-radius: 8px;
        position: relative;
        z-index: 2;
        li {
            font-family: "SuisseIntl-Regular";
            font-size: 18px;
            line-height: 28px;
            color: #001c60;
            background: white;
            padding: 12px 60px 12px 30px;
            list-style: none;
            list-style: none;
            border-bottom: 1px solid #f3eeee;
            min-height: 70px;
            position: relative;
            cursor: pointer;
            transition: all ease 0.3s;
            display: flex !important;
            align-items: center;
            vertical-align: middle;
            @include media-breakpoint-down(md) {
                padding: 20px;
                display: block !important;
            }
            @include media-breakpoint-down(md) {
                span {
                    font-family: "SuisseIntl-bold";
                    margin-bottom: 10px;
                    display: block;
                }
            }

            &:last-child {
                border: none;
            }

            @include media-breakpoint-up(md) {
                &:after {
                    content: "";
                    height: 15px;
                    width: 8px;
                    display: block;
                    right: 25px;
                    position: absolute;
                    background-size: cover;
                    background-image: url("../img/icon/arr-r.svg");
                }
                &.active,
                &:hover {
                    color: #fff;
                    background: #0038b0;
                    transition: all ease 0.3s;
                    &:after {
                        content: "";
                        background-image: url("../img/icon/arr-r2.svg");
                    }
                }
            }
        }
    }
    .blocktext {
        background: #ffffff;
        box-shadow: 0px 20px 25px -5px rgba(93, 96, 117, 0.1);
        border-radius: 8px;
        position: absolute;
        right: 0;
        z-index: 1;
        height: calc(100% + 60px);
        margin-top: -30px;
        display: flex !important;
        align-items: start;
        vertical-align: top;
        @include media-breakpoint-down(md) {
            display: none !important;
        }
        .content-text {
            font-family: "SuisseIntl-Regular";
            font-size: 16px;
            line-height: 24px;
            color: #37425e;
            width: 100%;
            padding: 55px 65px 55px 125px;
        }
    }
}
