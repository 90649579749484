/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
    background: $blue2;
    font-family: "SuisseIntl-Regular";
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;
    z-index: 5;
    position: relative;

    .footer-col-rs {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        .item-logo {
            img {
                width: 160px;
            }
        }
        .rs {
            @include media-breakpoint-down(md) {
                margin: auto;
                display: table;
            }
            li {
                float: left;
                margin-right: 10px;
                a {
                    height: 27px;
                    width: 27px;
                    border: 1px solid white;
                    border-radius: 50%;
                    transition: all ease 0.4s;
                    span {
                        margin: auto;
                        display: flex;
                        img {
                            max-height: 20px;
                            max-width: 20px;
                        }
                    }
                    &:hover {
                        opacity: 0.6;
                        transition: all ease 0.4s;
                    }
                }
            }
        }
    }
    .footer-col-1,
    .footer-col-2 {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        h2 {
            font-family: "SuisseIntl-Bold";
            font-size: 13px;
            line-height: 16px;
            text-transform: uppercase;
            color: #ffffff;
        }
        a {
            font-family: "SuisseIntl-Regular";
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
            padding-bottom: 10px;
            @include media-breakpoint-down(lg) {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }
    .footer-col-3 {
        @include media-breakpoint-down(md) {
            text-align: center;
            display: table;
        }
    }

    .footer-col-ml {
        display: block;
        text-align: center;
        background-color: #0035a8;
        li {
            font-family: "SuisseIntl-Regular";
            font-size: 12px;
            line-height: 18px;
            color: #ffffff;
            display: inline-block;
            a,
            button {
                font-family: "SuisseIntl-Regular";
                font-size: 12px;
                line-height: 18px;
                color: #ffffff;
                border: none;
                &:hover {
                    border: none;
                    color: #ffffff;
                    background: none;
                }
            }
        }
    }
}
