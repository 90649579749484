/*
|--------------------
|      NEWS
|--------------------
*/

#page-actualites-archive {
    .block-filters {
        vertical-align: middle;
        align-items: center;
        .desc {
            font-family: "SuisseIntl-Regular";
            font-size: 18px;
            line-height: 28px;
            color: #37425e;
        }
        .filters {
            float: right;
            @include media-breakpoint-down(lg) {
                float: left;
            }
            li {
                display: inline-block;
                font-family: "SuisseIntl-Regular";
                font-size: 16px;
                line-height: 24px;
                color: #182989;
                border: 1px solid #182989;
                border-radius: 8px;
                cursor: pointer;
                padding: 5px 14px;
                margin-right: 10px;
                @include media-breakpoint-down(xs) {
                    width: 100%;
                    text-align: center;
                }
                &:hover,
                &.active {
                    color: white;
                    background-color: #182989;
                }
            }
        }
    }
}

.list-container {
    &.loading {
        position: relative;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.3;
        transition: all ease 0.3s;
        &::after {
            z-index: 3 !important;
            display: block;
            width: 3.5rem;
            aspect-ratio: 1/1;

            position: absolute;
            top: calc(50% - 1.75rem);
            left: calc(50% - 1.75rem);
            z-index: 10;

            background-image: url(../img/svg-loaders/puff-black.svg);
            background-position: center;
            background-size: contain;

            content: "";
        }
    }
}

#block-similar-actus {
    .block-btn {
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
        .btn {
            &:hover {
                color: white;
            }
        }
    }
}
