/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
    @if is-enabled("background-images") {
        @extend .bg-cover;
    }

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding-block: var(--header-height);
    background-color: $black;
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
    .custom-card-link {
        color: $blue;
        display: block;
        border: 2px solid #f1f1f1;
        position: relative;
        .bg-image {
            width: 100%;
            height: 241px;
            display: block;
            background-size: cover;
            background-position: center;
        }
        .details {
            margin: 30px;
            min-height: 200px;
            margin: 30px;
            display: block;
            position: relative;
            @include media-breakpoint-down(xl) {
                min-height: initial;
            }
            @include media-breakpoint-down(sm) {
                margin: 15px;
            }
            .title {
                font-family: "SuisseIntl-Regular";
                font-size: 20px;
                line-height: 30px;
                letter-spacing: -0.01em;
                color: #37425e;
                display: block;
                @include media-breakpoint-down(xl) {
                    font-size: 17px;
                    line-height: 23px;
                }
            }
            .block-bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                vertical-align: middle;
                align-items: center;
                @include media-breakpoint-down(xl) {
                    position: initial;
                }
                .date {
                    font-family: "SuisseIntl-Regular";
                    font-size: 14px;
                    line-height: 22px;
                    color: #37425e;
                    float: left;
                }
                .more {
                    font-family: "SuisseIntl-Bold";
                    font-size: 13px;
                    line-height: 16px;
                    text-transform: uppercase;
                    display: block;
                    float: left;
                    margin-left: 20px;
                    &:before {
                        content: "";
                        width: 16px;
                        height: 16px;
                        display: block;
                        float: right;
                        margin: 0 5px 0 4px;
                        transition: all ease 0.3s;
                        transform: rotate(180deg);
                        background-image: url("../img/icon/arrow-b.svg");
                    }
                }
            }
        }
        &:hover {
            box-shadow: 0 0 6px #ebebeb;
            transition: all ease 0.4s;
            .card-img {
                transform: scale(1.05);
            }
            .more {
                &:before {
                    margin: 0 0 0 8px !important;
                }
            }
        }
    }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}

.page-offset {
    padding-top: 160px;
}

/*
|
| CMS
|------
*/
.cms {
    p,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "SuisseIntl-Regular";
    }
    p {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 15px;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    ul,
    ol {
        padding: 15px;

        li {
            &:not(:first-child) {
                margin-top: 0.25em;
            }
        }
    }

    ul {
        li {
            list-style: disc;
            margin-bottom: 15px;
            ul {
                li {
                    list-style: circle;
                }
            }
        }
    }

    ol {
        list-style-type: none;
        counter-reset: ordered;
        li {
            margin-left: 15px;
            margin-bottom: 15px;
            &::before {
                content: counter(ordered);
                border: 2px solid #182989;
                border-radius: 50%;
                color: #fff;
                counter-increment: ordered;
                display: inline-block;
                font-weight: 100;
                margin-left: -29px;
                margin-top: -3px;
                width: 25px;
                height: 25px;
                position: absolute;
                background: #182989;
                text-align: center;
            }
        }
    }

    a:not([class]) {
        text-decoration: underline;
    }
    hr {
        height: 1px;
        width: 100%;
        display: block;
        margin: 50px 0 40px 0;
        background: #eaecf0;
        border: none;
    }
}
