#page-flexible {
    padding-top: 109px;
    position: relative;
    #block-intro-flexible {
        width: 100%;
        .bg {
            width: 100%;
            height: 700px;
            background-size: cover;
            background-position: center;
            @include media-breakpoint-down(lg) {
                height: 300px;
            }
        }
    }
    .block-intro {
        height: 700px;
        top: 110px;
        left: 0;
        right: 0;
        position: absolute;
        @include media-breakpoint-down(lg) {
            position: relative;
            height: initial;
            top: initial;
            left: 0;
            right: 0;
        }
        .block-fil-ariane {
            position: absolute;
            top: 50px;
            @include media-breakpoint-down(lg) {
                top: 0px;
                position: relative;
            }
        }
        .bg {
            width: 100%;
            background-size: cover;
            background-position: center;
            height: 300px;
        }
        .intro {
            height: 700px;
            @include media-breakpoint-down(lg) {
                height: initial;
            }
        }
    }
    #block_file {
        color: white;
        background-color: $blue2;
        h2 {
            color: white;
            b,
            strong {
                color: white;
            }
        }
    }
    #block-chiffres {
        background-color: $blue2;
        h2 {
            color: white;
            b,
            strong {
                color: white;
            }
            &:before {
                content: "";
                background-color: white;
            }
        }
        .item {
            h2 {
                color: white;
            }
            .value {
                .chiffre {
                    color: white;
                }
                .unite {
                    color: white;
                }
            }
            .description {
                color: white;
            }
        }
    }
}
