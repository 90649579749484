/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| DECLARATION
|----------------
|
*/
$black: #000;
$very-dark-grey: #141414;
$dark-grey: #424242;
$grey: #999;
$light-grey: #dedede;
$very-light-grey: #fafafa;
$white: #fff;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);
$blue: #182989;
$blue2: #0038b0;
$light-blue: #f2f4ff;

/*
|
| DEFAULTS
|----------------
|
*/
$border-color: $grey;
$text-color: $very-dark-grey;
$default-background-overlay: $black-opacity;

$blue: $blue;
$blue2: $blue2;
$light-blue: $light-blue;
