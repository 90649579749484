/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "SuisseIntl-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

h1 {
  font-family: "SuisseIntl-Black";
}

h2 {
  font-family: "Suisse Intl";
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 71px;
  color: $black;
  @include media-breakpoint-down(md) {
    font-size: 34px;
    line-height: 46px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "SuisseIntl-Regular";
}

button,
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: inherit;
}

.z1 {
  z-index: 1;
}

.m-a-mob {
  @include media-breakpoint-down(md) {
    margin: auto;
  }
}
.o-h {
  overflow: hidden;
}
.clearfix {
  clear: both;
  width: 100%;
}

.sstitle {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $black;
  opacity: 0.6;
  text-transform: uppercase;
}

.bg-light-blue {
  background-color: #f2f4ff;
}

a,
img,
button {
  display: inline-block;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
}

.w100 {
  width: 100%;
}

.z2 {
  z-index: 2;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.dflex {
  display: flex !important;
  align-items: center;
  vertical-align: middle;
}

.p-0 {
  padding-left: 0;
  padding-right: 0;
}

.m-a {
  margin-left: auto;
  margin-right: auto;
}

.txt-c {
  text-align: center;
}
.fl-r {
  float: right;
  @include media-breakpoint-down(md) {
    float: left;
  }
}

.txt-r {
  text-align: right;
  @include media-breakpoint-down(md) {
    text-align: left;
  }
}
.bg-blue {
  background-color: $blue;
}
.bg-blue2 {
  background-color: $blue2;
  h2 {
    &:before {
      content: "";
      background-color: #fff;
    }
  }
}

.fil-ariane {
  font-family: "SuisseIntl-Regular";
  font-size: 14px;
  line-height: 24px;
  color: #001c60;
  a {
    float: left;
    color: #667290;
    &:hover {
      color: #001c60;
    }
  }
  b {
    float: left;
    display: contents;
  }
  span {
    float: left;
    height: 10px;
    width: 7px;
    display: block;
    background-position: center;
    background-size: cover;
    margin: 6px 10px;
    background-image: url(../img/icon/sep.svg);
  }
}

.disp-desk {
  display: block !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.disp-mob {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}
.disp-desk-lg {
  display: block !important;
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.disp-mob-lg {
  display: none !important;
  @include media-breakpoint-down(lg) {
    display: block !important;
  }
}

.disp-mob-sm {
  display: none !important;
  @include media-breakpoint-down(sm) {
    display: block !important;
  }
}

.card-img-container {
  overflow: hidden;
  display: block;
  &:hover {
    .card-img {
      transform: scale(1.05);
    }
  }
  .card-img {
    transition: all ease 0.6s;
  }
}
.c-b {
  clear: both;
}

.d-b {
  display: block !important;
}
.ta-c {
  text-align: center;
}

.rel {
  position: relative;
}

.btn {
  font-family: "SuisseIntl-Bold";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  color: $blue;
  border-radius: 2px;
  border: 1px solid $blue;
  background-color: transparent;
  display: inline-block;
  position: relative;
  padding: 17px 35px 16px 35px;
  transition: all ease 0.3s;
  &:hover {
    color: #ffffff;
    border: 1px solid $blue;
    background-color: $blue;
    transition: all ease 0.3s;
  }
  &.btn-alt {
    color: $blue;
    border: 1px solid $white;
    background-color: $white;
    &:hover {
      color: $white;
      border: 1px solid $white;
      background-color: transparent;
      transition: all ease 0.3s;
    }
  }
  &.btn-cadre {
    color: $white;
    border: 1px solid $white;
    background-color: transparent;
    &:hover {
      color: $blue;
      border: 1px solid $white;
      background-color: $white;
      transition: all ease 0.3s;
    }
  }
}

.videoyt {
  width: 100%;

  .plyr {
    .item-poster {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      opacity: 1;
      transition: all ease 0.4s;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .plyr__control--overlaid {
    background-image: linear-gradient(90deg, $blue 0%, $blue 49%, $blue 100%);
    padding: 30px;
    @include media-breakpoint-down(md) {
      padding: 20px;
    }

    svg {
      color: $blue !important;
      height: 18px;
      width: 18px;
    }
  }

  .plyr__control--overlaid:hover {
    background-image: linear-gradient(90deg, $blue 0%, $blue 49%, $blue 100%);

    svg {
      color: white !important;
    }
  }

  .plyr--video {
    background: transparent !important;
  }

  .plyr__video-wrapper {
    background: transparent !important;
  }

  .plyr--full-ui input[type="range"] {
    color: $blue;
  }

  .plyr__video-embed iframe {
    top: -50%;
    height: 200%;
    width: 101%;
  }

  .plyr__poster {
    background-size: cover;
  }

  .plyr__control--overlaid {
    background: white;
  }

  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded="true"] {
    background: $blue;
  }

  .plyr__control.plyr__tab-focus {
    box-shadow: 0 0 0 5px rgba($blue, 0.5);
  }

  .plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
    background: $blue;
  }
}

.title-page {
  font-family: "SuisseIntl-Regular";
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
  position: relative;
  margin-left: 20px;
  hyphens: auto;
  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-left: 0;
  }
  &:before {
    content: "";
    width: 3px;
    height: 100%;
    background-color: #cf3120;
    position: absolute;
    display: block;
    left: -20px;
    @include media-breakpoint-down(sm) {
      left: 0px;
      right: 0px;
      margin: auto;
      width: 90px;
      height: 3px;
      top: initial;
      bottom: -10px;
    }
  }
  strong,
  b {
    font-family: "SuisseIntl-Bold";
    font-size: 56px;
    line-height: 56px;
    text-transform: uppercase;
    color: #182989;
    margin-top: 10px;
    display: block;
    @include media-breakpoint-down(sm) {
      font-size: 36px;
      line-height: 36px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 26px;
      line-height: 28px;
    }
  }
}

.title-page-center {
  font-family: "SuisseIntl-Regular";
  font-size: 44px;
  line-height: 90px;
  text-transform: uppercase;
  position: relative;
  hyphens: auto;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  &:before {
    content: "";
    width: 44px;
    height: 3px;
    background-color: #cf3120;
    position: absolute;
    display: inline-block;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
  }
  strong,
  b {
    font-family: "SuisseIntl-Bold";
    text-transform: uppercase;
    color: #182989;
    margin-top: 10px;
  }
}
.block-progressbar {
  background: #37425e;
  height: 1px;
  width: 429px;
  left: 0;
  position: relative;
  margin: auto;
  @include media-breakpoint-down(md) {
    width: 270px;
  }
  .progressbar {
    height: 3px;
    top: -1px;
    left: 0;
    border-radius: 10px;
    position: absolute;
    background-color: #cf3120;
    transition: all ease 1s;
  }
}
.block-swiper-buttons {
  height: 40px;
  width: 110px;
  position: absolute;
  right: 0;
  top: -19px;
  @include media-breakpoint-down(md) {
    margin: auto;
    position: relative;
    top: 0;
  }
  .swiper-buttons {
    top: initial;
    left: 0;
    position: absolute;
    bottom: 0px;
    z-index: 1;
    height: 40px;
    width: 110px;
    @include media-breakpoint-down(md) {
      top: -60px;
      right: 0;
      margin: auto;
      display: block;
      bottom: initial;
    }

    .button-next,
    .button-prev {
      height: 40px;
      width: 40px;
      float: left;
      margin-left: 15px;
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 50%;
      transition: all ease 0.3s;
      z-index: 1;
      margin: 0px;
      &:before {
        content: "";
        background-image: url("../img/icon/arrow-right.svg");
        background-size: cover;
        height: 40px;
        width: 40px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        transform: rotate(180deg);
        @include media-breakpoint-down(md) {
          top: 28px;
        }
      }
      &.swiper-button-disabled {
        &:before {
          opacity: 0.4;
        }
      }
    }
    .button-next {
      position: absolute;
      right: 0;
      left: initial;
      &:before {
        content: "";
        transform: rotate(0deg);
      }
    }
  }
}
