/*
|--------------------
|       Contact
|--------------------
*/

#page-contact,
#page-form {
    height: 100vh;
    padding-top: 140px;
    .title-page {
        font-family: "SuisseIntl-Bold";
        font-size: 50px;
        line-height: 50px;
        @include media-breakpoint-down(sm) {
            font-size: 32px;
            line-height: 30px;
        }
    }
    .text-contact {
        font-family: "SuisseIntl-Regular";
        font-size: 17px;
        line-height: 28px;
        color: #37425e;
        width: 440px;
        @include media-breakpoint-down(sm) {
            margin: 25px auto;
            text-align: center;
            width: 100%;
        }
    }
    .question-contact {
        font-family: "Suisse Intl";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.03em;
        color: #001c60;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
    .step-contact {
        font-family: "SuisseIntl-Regular";
        font-size: 74px;
        line-height: 74px;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: #182989;
        @include media-breakpoint-down(sm) {
            font-size: 50px;
            line-height: 50px;
        }
    }
    .back-contact {
        font-family: "SuisseIntl-Bold";
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        color: #182989;
        &:before {
            content: "";
            width: 16px;
            height: 16px;
            display: block;
            float: left;
            margin: 0 0 0 5px;
            transition: all ease 0.3s;
            background-image: url(../img/icon/arrow-b.svg);
        }
        &:hover {
            opacity: 0.7;
            transition: all ease 0.3s;
            &:before {
                margin: 0 5px 0 0;
                transition: all ease 0.3s;
            }
        }
    }
    .fil-ariane {
        font-family: "SuisseIntl-Regular";
        font-size: 14px;
        line-height: 18px;
        color: #667291;
        b {
            color: #001c60;
        }
    }
    .questions {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        vertical-align: middle;
        align-items: center;
        display: grid;
        padding-top: 160px;
        @include media-breakpoint-down(lg) {
            position: relative;
            padding-top: 0;
        }
        .btn {
            border-radius: 8px;
            font-size: 16px;
            font-family: SuisseIntl-Regular;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-transform: none;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
        .dflex-questions {
            display: flex !important;
            align-items: center;
            vertical-align: middle;
            @include media-breakpoint-down(lg) {
                display: block !important;
                width: 100%ÒÒ;
            }
        }
    }
    .bottom-contact {
        position: absolute;
        bottom: 15px;
        @include media-breakpoint-down(lg) {
            position: relative;
            bottom: initial;
            margin-top: 20px;
        }
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
}

#page-form {
    .text-contact {
        width: 100%;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
    .detail-contact {
        height: calc(100vh - 150px);
        @include media-breakpoint-down(lg) {
            height: initial;
        }
    }
    .bottom-contact {
        position: fixed;
        @include media-breakpoint-down(lg) {
            position: relative;
        }
    }
    .gform_heading {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        .gform_footer {
            text-align: center;
            display: block;
        }
    }
    form {
        margin-bottom: 40px;
        .gfield {
            margin-bottom: 10px;
        }
        label,
        legend {
            font-family: "SuisseIntl-Regular";
            font-size: 16px;
            line-height: 24px;
            color: #001c60;
            a {
                color: #37425e;
                text-decoration: underline;
                font-weight: 700;
            }
        }
        input,
        select,
        textarea {
            color: #37425e;
            font-family: "SuisseIntl-Regular";
            font-size: 16px;
            line-height: 22px;
            border-radius: 4px;
            padding: 15px 18px;
            border: 1px solid #667291;
        }
        input[type="number"] {
            text-align: center;
            padding: 15px 5px !important;
        }
        .ginput_container_date {
            input {
                width: 100%;
            }
        }
        .gfield_radio {
            .gchoice {
                margin-bottom: 10px;
            }
        }
        .gfield_consent_description {
            border: none;
            font-family: "SuisseIntl-Regular";
            font-size: 14px;
            line-height: 22px;
            margin-top: 20px;
            max-height: initial;
            overflow-y: initial;
            padding: 0;
            width: 100%;
            color: #37425e;
            a {
                color: #37425e;
                text-decoration: underline;
                font-weight: 700;
            }
        }
        .block-montant {
            position: relative;
        }
        .gform_button {
            font-family: "SuisseIntl-Bold";
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            cursor: pointer;
            text-align: center;
            color: white;
            border-radius: 2px;
            border: 1px solid #182989;
            background-color: #182989;
            display: inline-block;
            position: relative;
            padding: 17px 35px 16px 35px;
            transition: all ease 0.3s;
            &:hover {
                color: #182989;
                background-color: transparent;
            }
        }
        .montant {
            position: absolute;
            bottom: 0;
            right: 0;
            @include media-breakpoint-down(sm) {
                position: relative;
            }
            .label-montant {
                font-family: "SuisseIntl-Regular";
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                color: #001c60;
                @include media-breakpoint-down(sm) {
                    text-align: left;
                }
            }
            .tarif {
                font-family: "SuisseIntl-Regular";
                font-size: 24px;
                line-height: 24px;
                text-align: right;
                color: #182989;
                padding: 20px 0 8px 0;
                word-break: break-all;
                @include media-breakpoint-down(sm) {
                    text-align: left;
                    padding: 10px 0 8px 0;
                }
                #price,
                .unity {
                    font-family: "SuisseIntl-Bold";
                    font-size: 32px;
                    line-height: 28px;
                }
            }
            .desc {
                font-family: "SuisseIntl-Regular";
                font-size: 12px;
                line-height: 22px;
                color: #37425e;
            }
        }
        .block-form-tel {
            position: relative;
            display: flex;
            vertical-align: middle;
            align-items: center;
            .form-tel {
                font-family: "SuisseIntl-Regular";
                font-size: 14px;
                line-height: 22px;
                text-align: right;
                color: #182989;
                position: absolute;
                margin-top: 110px;
                @media (max-width: 640px) {
                    margin-top: 0;
                    position: relative;
                }
                @include media-breakpoint-down(sm) {
                    margin: auto;
                }
                a {
                    color: #37425e;
                    text-decoration: underline;
                    font-weight: 700;
                }
            }
        }
    }
}
.productTotal {
    text-align: right;
    .ginput_total {
        border: none !important;
        text-align: right;
        font-family: "SuisseIntl-Bold" !important;
        font-size: 32px !important;
        line-height: 28px !important;
        padding: 0 !important;
    }
    .gfield_description {
        color: $blue;
    }
}
