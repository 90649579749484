/*
|-------------
| Breakpoints media queries creation 
|-------------
| - How to use: add lines to create more breakpoints
|
*/
$breakpoints: (
    xs: 480px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1400px,
);
