body {
    &.menu-active {
        overflow: hidden;
        .item-burger > span {
            background: #a8acc3 !important;
            transition: all ease 0.4s;
        }
    }
}

/*
|
| Header
|---------
*/
%link-menu {
    text-transform: uppercase;
    color: $very-dark-grey;
    font-size: 14px;
    font-weight: 400;
}

#header {
    top: 0;
    width: 100%;
    z-index: 10;
    display: block;
    position: fixed;
    transition: top 0.6s;
    box-shadow: 0px 12px 56px rgb(6 28 61 / 10%);
    background: white;
    .header-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item-logo {
            img {
                height: 75px;
            }
        }
        .item-nav {
            display: none;

            .item-menu {
                display: flex;

                li {
                    font-family: "SuisseIntl-Bold";
                    font-size: 12px;
                    line-height: 28px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    position: relative;
                    &:after {
                        content: "";
                        height: 2px;
                        width: 0;
                        position: absolute;
                        left: 0;
                        bottom: 28px;
                        transition: all ease 0.5s;
                        background-color: $blue;
                    }
                    &:hover,
                    &.current-menu-item {
                        &:after {
                            width: 100%;
                            transition: all ease 0.5s;
                        }
                    }

                    &:not(:last-child) {
                        margin-right: 30px;
                        @include media-breakpoint-down(xl) {
                            margin: 0 7px;
                        }
                    }

                    &.acces-site-desk {
                        cursor: pointer;
                    }
                    .item-link {
                        color: $blue;
                        padding: 33px 0;
                        @include media-breakpoint-down(xl) {
                            font-size: 11px;
                            line-height: 28px;
                        }
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                display: flex;
                align-items: center;

                .item-menu {
                    margin-right: 0;
                }
            }
            @include media-breakpoint-down(lg) {
                .item-menu {
                    margin-right: 25px;
                }
            }
        }
    }

    .btn {
        @include media-breakpoint-down(xl) {
            padding: 17px 12px 16px 12px;
        }
        @include media-breakpoint-down(lg) {
            position: absolute;
            right: 45px;
            top: 16px;
        }
        @include media-breakpoint-down(xs) {
            padding: 17px 5px 16px 5px;
        }
        @media (max-width: 360px) {
            display: none;
        }
    }
    .btn-menu {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        margin-top: 5px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        & > .item-burger {
            display: block;
            width: 25px;
            transition: all ease 0.8s !important;

            & > span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: $blue;
                border-radius: 2px;

                &:nth-child(2) {
                    margin: 6px 0;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.sm-menu {
    border-radius: 0px;
    border: 0px;
    top: 93px;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
    background: white;
    z-index: 9;
    display: none;
    .block-ss-menu {
        padding-top: 30px;
        padding-bottom: 30px;
        .intro {
            .title-page {
                strong {
                    font-size: 44px;
                }
            }

            .text {
                font-family: "SuisseIntl-Regular";
                font-size: 16px;
                line-height: 24px;
                color: #37425e;
                text-transform: initial;
            }
        }
        .content {
            .link {
                .bg {
                    width: 100%;
                    height: 248px;
                    display: block;
                    background-size: cover;
                    background-position: center;
                    position: relative;
                    &:after {
                        content: "";
                        background-color: #000;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        opacity: 0.3;
                        z-index: 0;
                    }
                }
                .title {
                    font-family: "SuisseIntl-Bold";
                    font-size: 13px;
                    line-height: 16px;
                    display: flex;
                    align-items: flex-end;
                    text-transform: uppercase;
                    color: #ffffff;
                    position: absolute;
                    bottom: 15px;
                    left: 15px;
                    right: 15px;
                    z-index: 1;
                    @include media-breakpoint-down(lg) {
                        font-size: 12px;
                        left: 10px;
                        right: 10px;
                    }
                }
                .text {
                    display: block;
                }
            }
        }
    }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    padding: var(--header-height) var(--side-margin);
    align-items: center;
    background-color: $white;
    overflow: auto;
    opacity: 0;
    visibility: hidden;

    ul {
        margin: auto;

        li {
            &:not(:last-child) {
                margin-bottom: 5px;
            }

            a {
                text-transform: uppercase;
                font-size: 26px;
                line-height: initial;
                font-weight: 500;
                color: $blue;
                padding: 6px 0;
            }
            .nav-drop {
                margin: 0 10px 10px;
                li {
                    a {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.offset-menu {
    padding-top: 124px;
}
