/*
|--------------------
|      SINGLE
|--------------------
*/
.back {
    font-family: "SuisseIntl-Bold";
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #182989;
    &:before {
        content: "";
        width: 16px;
        height: 16px;
        display: block;
        float: left;
        margin: 0 0 0 5px;
        transition: all ease 0.3s;
        background-image: url("../img/icon/arrow-b.svg");
    }
    &:hover {
        opacity: 0.7;
        transition: all ease 0.3s;
        &:before {
            margin: 0 5px 0 0;
            transition: all ease 0.3s;
        }
    }
}
h1 {
    font-family: "SuisseIntl-Black";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    color: $blue;
}
h2 {
    font-family: "Suisse Intl";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.03em;
    color: #001c60;
    margin-bottom: 15px;
}
.post-date {
    font-family: "SuisseIntl-Regular";
    font-size: 14px;
    line-height: 22px;
    color: $blue;
}
.intro {
    font-family: "SuisseIntl-Regular";
    font-size: 18px;
    line-height: 28px;
    color: #37425e;
}
.thumbnail {
    max-width: 100%;
}
#page-news-single {
    p,
    li,
    .ol,
    a {
        color: #37425e;
    }
}
