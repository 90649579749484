@use "sass:math";
@mixin generate-containers() {
    .container,
    .container-fluid {
        width: calc(100% - 2 * var(--container-gutter, #{math.div($grid-col-gap, 2)}));

        margin-inline: auto;
    }

    .container {
        max-width: var(--container-width, none);

        @each $breakpoint, $max-width in $containers {
            @include media-breakpoint-up($breakpoint) {
                --container-width: #{$max-width};
            }
        }
    }
}
